
import logo from './logo_transparent.png';
import './HomePage.css';
import backgroundImage from './nasa.jpg'

function HomePage() {
  const styles = {
    backgroundImage: `url(${backgroundImage})`,
  };

  return (
    <div className="HomePage">
      <header className="HomePage-header" style={styles}>
        <img src={logo} className="HomePage-logo" alt="logo" />
      </header>
    </div>
  );
}

export default HomePage;
