import React, { useState, useEffect } from "react";
import "./Menu.css";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { APP_URL , APP_NAME , DOCS_APP_NAME, DOCS_APP_URL, GITLAB_URL , GITLAB_NAME } from '../../globalConfig.js'


function Menu() {
  const [isTransparent, setIsTransparent] = useState(true);
  const [isHomePage] = useState(window.location.pathname === "/");


  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset < 80) {
        setIsTransparent(true);
      } else {
        setIsTransparent(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Navbar className={`navbar navbar-expand-lg fixed-top ${isTransparent ? "navbar-transparent" : ""}`}>
        <Navbar.Brand href={ APP_URL }>{ APP_NAME }</Navbar.Brand>
        <Nav className="ml-auto">
          {isHomePage ? null : <Nav.Link href={ APP_URL }>Home</Nav.Link>}
          <Nav.Link href={ DOCS_APP_URL }>{DOCS_APP_NAME}</Nav.Link>
          <Nav.Link href={ GITLAB_URL }>{GITLAB_NAME}</Nav.Link>
          {/* <NavDropdown title="Projekty" id="basic-nav-dropdown">
              <NavDropdown.Item href="#">Własny Lab</NavDropdown.Item>
              <NavDropdown.Item href="#">Procesy CI</NavDropdown.Item>
              <NavDropdown.Item href="#">Procesy CD</NavDropdown.Item>
            </NavDropdown> */}
        </Nav>
    </Navbar>

  );
}

export default Menu;