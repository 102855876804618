import React, { useState } from 'react';
import Cookies from 'js-cookie';
import "./Footer.css";
import 'bootstrap/dist/css/bootstrap.css';
import packageJson from '../../../package.json';


function Footer(){

  const currentYear = new Date().getFullYear();
  const isCookieAccepted = Cookies.get('cookie-accept-policy');
  if(isCookieAccepted == null) Cookies.set('cookie-accept-policy',false)

  const acceptPolicyCokie = () => {
    Cookies.set('cookie-accept-policy', true);
    window.location.reload()
  };

  return (
    <footer class="footer">
        <div class="credits ml-auto">
            <span class="version">
                Wersja strony: {packageJson.version}
            </span>
            <span class="copyright">
                © {currentYear} , created by MACIEJ RACHUNA
            </span>
        </div>
        {isCookieAccepted == 'true' ? '' :
            <div class="cookies">
                <div class="cookies-info">
                    <span>
                        W ramach naszej witryny stosujemy pliki cookies w celu świadczenia usług na najwyższym poziomie, w tym w
                        sposób dostosowany do indywidualnych potrzeb.
                        Korzystanie z witrny bez zmiany ustawień dotyczących cookies oznacza, że będą one zamieszczane w
                        urządzeniu.
                    </span>
                </div>
                <div class="cookies-accept">
                    <button onClick={acceptPolicyCokie} type="button" class="btn btn-primary">Akceptuje</button>
                </div>
            </div>
        }
  </footer>
  );

}

export default Footer;